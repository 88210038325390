import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from "react-router-dom";

import Login from "pages/login/Login";
import Error from "components/error/Error";
import Home from "pages/home/Home";
import AuthGuard from "utils/AuthGuard";
import AddProduct from "pages/product/add-product/AddProduct";
import BidList from "pages/bid/bid-list/BidList";
import Products from "pages/product/product-list/Products";
import PlaceBid from "pages/bid/place-bid/PlaceBid";
import RoleGuard from "utils/RoleGuard";
import BidWinners from "pages/bid/bid-winners/BidWinners";
import Settings from "pages/settings/Settings";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route errorElement={<Error />}>
        <Route element={<AuthGuard isProtected={false} />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<AuthGuard isProtected />} >
          <Route element={<Home />}>
            <Route path="/" element={<Products />} />
            <Route path="/products" element={<Products />} />
            <Route path="/bids">
              <Route path=":productId" element={<BidList />} />
              <Route path="place/:productId" element={<PlaceBid />} />
              <Route path="winners" element={<BidWinners />} />
            </Route>
            <Route path="/settings" element={<Settings />} />
            <Route path="/admin" element={<RoleGuard allowedRoles={["ADMIN"]} />}>
              <Route path="products/add" element={<AddProduct />} />
              <Route path="products/edit/:productId" element={<AddProduct />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </>
  )
);
