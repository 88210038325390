import { useNavigate } from "react-router-dom";
import { clearSessionData } from "services/session.service";
import { routes } from "constants/routes";
import "./Settings.css";

const Settings: React.FC = () => {
    const navigate = useNavigate();

    function handleLogout() {
        clearSessionData();
        navigate(routes.login);
    }

    return (
        <div className="settings">
            <div className="menu-item" onClick={handleLogout}>
                <div>
                    <i className="bi bi-box-arrow-right"></i>
                </div>
                <div className="menu-item-button">
                    <div>
                        Logout
                    </div>
                    <div>
                        <i className="bi bi-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;
