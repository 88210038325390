import React, { useCallback, useContext } from "react";
import { Bid, BidCreateDTO } from "interfaces/Bid";
import { useAuthInterceptor } from "utils/AuthInterceptorProvider";
import { omit } from "utils/array.util";

const BidContext = React.createContext<{
  getBidsByProductId: (productId: string) => Promise<Bid[] | undefined>;
  placeBid: (data: BidCreateDTO) => Promise<Bid | undefined>;
}>({
  getBidsByProductId: () => { throw Error("Method not implemented") },
  placeBid: () => { throw Error("Method not implemented") }
});

interface Props {
  children: React.ReactNode;
}

export const BidProvider: React.FC<Props> = ({ children }) => {
  const { api } = useAuthInterceptor();

  const getBidsByProductId = useCallback(async (productId: string) => {
    try {
      const { data } = await api.get<{ data: Bid[] }>(
        `/bid/get-bids/${productId}`
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const placeBid = useCallback(async (bidData: BidCreateDTO) => {
    try {
      const bidRequestData = omit(bidData, ["minBidAmount"]);
      const { data } = await api.post<{ data: Bid }>(
        "/bid/create-bid",
        bidRequestData
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  return (<BidContext.Provider value={{ getBidsByProductId, placeBid }}>{children}</BidContext.Provider>);
};

export function useBid() {
  const bidContext = useContext(BidContext);

  return bidContext;
}
