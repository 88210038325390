import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import "./BackButton.css";

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <button type="button" onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
        </button>
    )
}

export default BackButton;
