import { useState } from "react";
import { useSession } from "providers/SessionProvider";
import { useProduct } from "providers/ProductProvider";
import { routes } from "constants/routes";
import { format } from "date-fns";
import { Product } from "interfaces/Product";
import { useNavigate } from "react-router-dom";
import { getImageSrc } from "utils/string.util";
import ConfirmationModal from "components/modals/ConfirmationModal/ConfirmationModal";
import "./ProductListItem.css";

interface ProductListItemProps {
    product: Product;
    allowEdit: boolean;
    allowDelete: boolean;
}

const ProductListItem: React.FC<ProductListItemProps> = ({ product, allowEdit, allowDelete }) => {
    const navigate = useNavigate();
    const [isShown, setIsShown] = useState<boolean>(false);
    const { updateResponseMessage } = useSession();
    const { getProducts, deleteProduct } = useProduct();

    function handleViewBids(productId: string) {
        navigate(routes.productBids(productId));
    }

    function handleEditProduct(productId: string) {
        navigate(routes.editProduct(productId));
    }

    async function handleDeleteProduct(productId: string) {
        try {
            await deleteProduct(productId);

            getProducts();

            updateResponseMessage("Auction item deleted", true);
        } catch (ex) {
            const error: { message: string } = ex as { message: string };
            updateResponseMessage(error.message, false);
        }
    }

    return (
        <div className="product-item">
            <div>
                <img src={getImageSrc(product.image1 || product.image2 || product.image3)} alt={product.name} />
            </div>
            <div className="product-item-button" onClick={() => handleViewBids(product.id)}>
                <div>
                    <h5>{product.name}</h5>
                    <p>{product.description}</p>
                    <p>
                        {`${format(new Date(product.bidStartTime), "PP p")} to ${format(new Date(product.bidEndTime), "PP p")}`}
                    </p>
                </div>
                <div className="ms-auto">
                    {allowEdit && (<button type="button" onClick={(e) => {
                        e.stopPropagation();
                        handleEditProduct(product.id)
                    }}>
                        <i className="bi bi-pencil-square"></i>
                    </button>)}
                    {allowDelete && (<button type="button" onClick={(e) => {
                        e.stopPropagation();
                        setIsShown(true);
                    }}>
                        <i className="bi bi-trash"></i>
                    </button>)}
                </div>
                <div>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>
            <ConfirmationModal
                isShown={isShown}
                title="Delete auction item"
                message={`Are you sure you want to delete ${product.name}`}
                confirmButtonText="Yes"
                declineButtonText="No"
                onHide={isConfirmed => {
                    setIsShown(false);

                    if (isConfirmed) {
                        handleDeleteProduct(product.id);
                    }
                }}
            />
        </div>
    )
}

export default ProductListItem;
