import { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useProduct } from "providers/ProductProvider";
import { useBid } from "providers/BidProvider";
import { routes } from "constants/routes";
import { Form } from "react-bootstrap";
import { BidCreateDTO } from "interfaces/Bid";
import { PlaceBidSchema } from "./place-bid.schema";
import "./PlaceBid.css";
import { currencyFormatter } from "utils/number.util";

const PlaceBid: React.FC = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const { updateSelectedProduct, selectedProduct } = useProduct();
    const { placeBid } = useBid();
    const { setValues, ...formik } = useFormik<BidCreateDTO>({
        initialValues: {
            productId: "",
            minBidAmount: 0,
            bidAmount: 0
        },
        validationSchema: PlaceBidSchema,
        onSubmit: values => {
            placeBid(values)
                .then(res => {
                    if (res) {
                        navigate(routes.home);
                    }
                })
        }
    });

    useEffect(() => {
        if (productId) {
            updateSelectedProduct(productId);
        }

        return () => updateSelectedProduct();
    }, [productId, updateSelectedProduct]);

    useEffect(() => {
        if (selectedProduct) {
            setValues({
                productId: selectedProduct.id,
                minBidAmount: selectedProduct.minBidAmount,
                bidAmount: 0
            });
        }
    }, [selectedProduct, setValues]);

    return (
        <div className="place-bid">
            <Form onSubmit={formik.handleSubmit} noValidate>
                <Form.Group>
                    <Form.Label htmlFor="name">Product</Form.Label>
                    <Form.Control
                        id="name"
                        name="name"
                        type="text"
                        value={selectedProduct?.name || ""}
                        readOnly
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="posted-by">Posted by</Form.Label>
                    <Form.Control
                        id="posted-by"
                        name="postedBy"
                        type="text"
                        value={selectedProduct?.user?.name || ""}
                        readOnly
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="min-bid-amount">Minimum bid amount</Form.Label>
                    <Form.Control
                        id="min-bid-amount"
                        name="minBidAmount"
                        type="text"
                        value={currencyFormatter.format(selectedProduct?.minBidAmount || 0)}
                        readOnly
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="bid-amount">Bid amount</Form.Label>
                    <Form.Control
                        id="bid-amount"
                        name="bidAmount"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bidAmount}
                        isInvalid={formik.touched.bidAmount && Boolean(formik.errors.bidAmount)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.bidAmount}
                    </Form.Control.Feedback>
                </Form.Group>
                <button type="submit" disabled={!formik.isValid}>Place bid</button>
            </Form>
        </div>
    )
}

export default PlaceBid;
