import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuctionItemGroup, Product, ProductCreateDTO, ProductWinner } from "interfaces/Product";
import { useAuthInterceptor } from "utils/AuthInterceptorProvider";
import { useSession } from "./SessionProvider";
import { isPast } from "date-fns";
import { AxiosError } from "axios";

const ProductContext = React.createContext<{
  productsList: Product[];
  selectedProduct: Product | null;
  addProduct: (productData: ProductCreateDTO) => Promise<Product | undefined>;
  editProduct: (id: string, productData: ProductCreateDTO) => Promise<Product | undefined>;
  deleteProduct: (id: string) => Promise<Product | undefined>;
  getProducts: () => Promise<void>;
  getProductWinners: () => Promise<ProductWinner[] | undefined>;
  updateSelectedProduct: (productId?: string) => void;
}>({
  productsList: [],
  selectedProduct: null,
  updateSelectedProduct: () => { },
  addProduct: () => { throw Error("Method not implemented") },
  editProduct: () => { throw Error("Method not implemented") },
  deleteProduct: () => { throw Error("Method not implemented") },
  getProducts: () => { throw Error("Method not implemented") },
  getProductWinners: () => { throw Error("Method not implemented") }
});

interface Props {
  children: React.ReactNode;
}

export const ProductProvider: React.FC<Props> = ({ children }) => {
  const { api } = useAuthInterceptor();
  const { token } = useSession();
  const [productsList, setProductsList] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const getProducts = useCallback(async () => {
    if (token) {
      try {
        const { data } = await api.get<{ data: Product[] }>(
          "/admin/auction/get-auction-items"
        );

        setProductsList(data.data);
      } catch (e) {
        console.log(e);
      }
    }
  }, [api, token]);

  const getProductWinners = useCallback(async () => {
    try {
      const { data } = await api.get<{ data: ProductWinner[] }>(
        "/auction/get-auction-winners"
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const addProduct = useCallback(async (productData: ProductCreateDTO) => {
    try {
      const formData = new FormData();

      formData.append("name", productData.name);
      formData.append("description", productData.description);
      formData.append("minBidAmount", String(productData.minBidAmount));
      formData.append("bidStartTime", String(productData.bidStartTime));
      formData.append("bidEndTime", String(productData.bidEndTime));
      formData.append("isDraft", String(productData.isDraft));

      const images = [productData.image1, productData.image2, productData.image3].filter(img => Boolean(img));

      images.forEach((img, i) => {
        formData.append(`image${i + 1}`, img as Blob);
      });

      const { data } = await api.post<{ data: Product }>(
        "/auction/create-auction",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const editProduct = useCallback(async (id: string, productData: ProductCreateDTO) => {
    try {
      const formData = new FormData();

      formData.append("name", productData.name);
      formData.append("description", productData.description);
      formData.append("minBidAmount", String(productData.minBidAmount));
      formData.append("bidStartTime", String(productData.bidStartTime));
      formData.append("bidEndTime", String(productData.bidEndTime));
      formData.append("isDraft", String(productData.isDraft));

      if (productData.image1 != null) {
        formData.append("image1", productData.image1 as Blob);
      }

      if (productData.image2 != null) {
        formData.append("image2", productData.image2 as Blob);
      }

      if (productData.image3 != null) {
        formData.append("image3", productData.image3 as Blob);
      }

      const { data } = await api.put<{ data: Product }>(
        `/auction/update-auction/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const deleteProduct = useCallback(async (id: string) => {
    try {
      const { data } = await api.delete<{ data: Product }>(
        `/auction/${id}`
      );

      return data.data;
    } catch (e) {
      console.log(e);

      const error: AxiosError = e as AxiosError;

      if (error.response) {
        return Promise.reject(error.response.data);
      }
    }
  }, [api]);

  const updateSelectedProduct = useCallback((productId?: string) => {
    if (!productId) {
      return setSelectedProduct(null);
    }

    setSelectedProduct(productsList.find(product => product.id === productId) || null);
  }, [productsList]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (<ProductContext.Provider value={{ productsList, selectedProduct, updateSelectedProduct, addProduct, editProduct, deleteProduct, getProducts, getProductWinners }}>{children}</ProductContext.Provider>);
};

export function useProduct() {
  const productContext = useContext(ProductContext);
  const [auctionItems, setAuctionItems] = useState<AuctionItemGroup>({
    ongoingAuctionItems: [],
    completedAuctionItems: [],
    draftAuctionItems: []
  });

  useEffect(() => {
    if (productContext.productsList.length) {
      const ongoingAuctionItems: Product[] = [];
      const completedAuctionItems: Product[] = [];
      const draftAuctionItems: Product[] = [];

      productContext.productsList.forEach(product => {
        if (product.isDraft) {
          draftAuctionItems.push(product);
          return;
        }

        if (product.bidEndTime && isPast(product.bidEndTime)) {
          completedAuctionItems.push(product);
        } else {
          ongoingAuctionItems.push(product);
        }
      });

      setAuctionItems({
        ongoingAuctionItems,
        completedAuctionItems,
        draftAuctionItems
      });
    }
  }, [productContext.productsList]);

  const biddingEndDate = productContext.selectedProduct?.bidEndTime ? new Date(productContext.selectedProduct?.bidEndTime) : null;

  return { biddingEndDate, auctionItems, ...productContext };
}
