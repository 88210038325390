import { routes } from "./routes"

import { Image } from "react-bootstrap"
import BackButton from "components/buttons/back-button/BackButton"

import logoImage from "assets/icons/logo.svg"
import NavigateButton from "components/buttons/navigate-button/NavigateButton"

export const headers = new Map([
  [
    routes.home,
    {
      startSlot: <Image src={logoImage} />,
      title: "Auction Masters",
      endSlot: null,
    },
  ],
  [
    routes.login,
    {
      startSlot: <Image src={logoImage} />,
      title: "Login",
      endSlot: null,
    },
  ],
  [
    routes.otp,
    {
      startSlot: null,
      title: "",
      endSlot: null,
    },
  ],
  [
    routes.settings,
    {
      startSlot: <Image src={logoImage} />,
      title: "Settings",
      endSlot: null,
    },
  ],
  [
    routes.productsList,
    {
      startSlot: <BackButton />,
      title: "Products",
      endSlot: null,
    },
  ],
  [
    routes.productBids("*"),
    {
      startSlot: <BackButton />,
      title: "Bids",
      endSlot: null,
    },
  ],
  [
    routes.placeBid("*"),
    {
      startSlot: <BackButton />,
      title: "Place Bid",
      endSlot: null,
    },
  ],
  [
    routes.bidWinners,
    {
      startSlot: <BackButton />,
      title: "Winners",
      endSlot: null,
    },
  ],
  [
    routes.addProduct,
    {
      startSlot: <BackButton />,
      title: "Add Product",
      endSlot: null,
    },
  ],
  [
    routes.editProduct("*"),
    {
      startSlot: <BackButton />,
      title: "Edit Product",
      endSlot: null,
    },
  ]
])
