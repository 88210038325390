import { ReactNode } from "react"
import "./SlottedHeader.css"

export interface SlottedHeaderProps {
  startSlot: ReactNode
  title: string
  endSlot: ReactNode
}

const SlottedHeader: React.FC<SlottedHeaderProps> = ({
  startSlot,
  title,
  endSlot,
}) => {
  return (
    <header className="slotted-header">
      <div>{startSlot}</div>
      <h3>{title}</h3>
      <div>{endSlot}</div>
    </header>
  )
}

export default SlottedHeader
