import * as Yup from "yup";

export const PlaceBidSchema = Yup.object().shape({
  productId: Yup.string().required(),
  minBidAmount: Yup.number().required(),
  bidAmount: Yup.number()
    .positive()
    .when("minBidAmount", ([minBidAmount], schema) => {
      return schema.min(
        minBidAmount,
        "Bid amount must be greater or equal to minimum bid amount"
      );
    })
    .required("Bid amount is required"),
});
