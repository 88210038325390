import { useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface MessageToastProps {
    message: string;
    isSuccess: boolean;
}

const MessageToast: React.FC<MessageToastProps> = ({ message, isSuccess }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(Boolean(message));
    }, [message]);

    return (
        <ToastContainer position="bottom-center" className="mb-1">
            <Toast
                bg={isSuccess ? "success" : "danger"}
                className="text-white"
                onClose={() => setShow(false)}
                show={show}
                delay={3000}
                autohide
            >
                <Toast.Body>
                    {message}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default MessageToast;
