import { routes } from "constants/routes";
import { UserRole } from "interfaces/User";
import { useSession } from "providers/SessionProvider";
import { Spinner } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";

interface RoleGuardProps {
    allowedRoles: UserRole[];
}

const RoleGuard = ({ allowedRoles = [] }: RoleGuardProps) => {
    const { user } = useSession();

    if (user.userRole === "UNKNOWN") {
        return <Spinner />
    }

    return (
        allowedRoles.includes(user.userRole)
            ? <Outlet /> : <Navigate to={routes.home} />
    );
}

export default RoleGuard;
