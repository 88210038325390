import { NavLink, Outlet, useLocation } from "react-router-dom";
import { routes } from "constants/routes";
import "./Home.css";
import { useEffect, useState } from "react";
import { headers } from "constants/headers";
import SlottedHeader, { SlottedHeaderProps } from "components/headers/slotted-header/SlottedHeader";
import MessageToast from "components/toasts/MessageToast";
import { useSession } from "providers/SessionProvider";

const defaultHeaderProps: SlottedHeaderProps = {
    startSlot: null,
    title: "",
    endSlot: null,
}

const Home: React.FC = () => {
    const location = useLocation();
    const { responseMessage } = useSession();
    const [header, setHeader] = useState<SlottedHeaderProps>(defaultHeaderProps)

    useEffect(() => {
        if (location.pathname) {
            const pathKey = location.pathname
                .split("/")
                .map((param) => (Number.parseInt(param) || param.length === 36 ? "*" : param))
                .join("/")

            setHeader(headers.get(pathKey) || defaultHeaderProps)
        }
    }, [location])


    return (
        <div className="home">
            <SlottedHeader
                startSlot={header.startSlot}
                title={header.title}
                endSlot={header.endSlot}
            />
            <main>
                <Outlet />
                <MessageToast message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
            </main>
            <footer>
                <NavLink to={routes.home}>
                    <i className="bi bi-house"></i>
                </NavLink>
                <NavLink to={routes.bidWinners}>
                    <i className="bi bi-trophy"></i>
                </NavLink>
                <NavLink to={routes.settings}>
                    <i className="bi bi-gear"></i>
                </NavLink>
            </footer>
        </div>
    )
}

export default Home;
