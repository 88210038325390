import { useEffect, useState } from "react";
import { formatDuration, intervalToDuration, isBefore } from "date-fns";
import { useProduct } from "providers/ProductProvider";
import { getImageSrc } from "utils/string.util";
import { ProductWinner } from "interfaces/Product";
import WinnerListItem from "./WinnerListItem";
import "./BidWinners.css";

const BidWinners: React.FC = () => {
    const { getProductWinners, selectedProduct, biddingEndDate } = useProduct();
    const [auctionWinners, setAuctionWinners] = useState<ProductWinner[]>([]);
    const [countdown, setCountdown] = useState<string>("");
    const [countdownEnded, setCountdownEnded] = useState<boolean>(false);

    useEffect(() => {
        getProductWinners()
            .then(res => {
                if (res) {
                    setAuctionWinners(res);
                }
            })
            .catch(ex => {
                console.log(ex);
            });
    }, [getProductWinners]);

    useEffect(() => {
        if (biddingEndDate) {
            const interval = setInterval(() => {
                const now = new Date();
                const duration = intervalToDuration({ start: now, end: biddingEndDate });

                if (isBefore(biddingEndDate, now)) {
                    setCountdownEnded(true);
                    clearInterval(interval);
                } else {
                    setCountdown(`${formatDuration(duration)}`);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [biddingEndDate]);

    return (
        <div className="bids">
            {selectedProduct && (<div className="product-overview">
                <div className="image-container">
                    <img src={getImageSrc(selectedProduct.image1)} alt={selectedProduct.name} />
                </div>
                <div>
                    <h3>{selectedProduct.name}</h3>
                    <p>{selectedProduct.description}</p>
                    <p>{countdownEnded ? "Bidding closed" : `Bidding ends in: ${countdown}`}</p>
                </div>
            </div>)}
            {auctionWinners.map(winner => (<div key={winner.id}>
                <WinnerListItem productWinner={winner} />
            </div>))}
        </div>
    )
}

export default BidWinners;
