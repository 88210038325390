import * as Yup from "yup";

export const AddProductSchema = Yup.object().shape({
  name: Yup.string().label("Name").required(),
  description: Yup.string(),
  minBidAmount: Yup.number().label("Min bid amount").positive().required(),
  bidStartTime: Yup.string().label("Bid start time").required(),
  bidEndTime: Yup.string().label("Bid end time").required(),
  images: Yup.array(Yup.mixed()),
});
