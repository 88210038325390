export const routes = {
  home: "/",
  login: "/login",
  otp: "/otp",
  settings: "/settings",
  productsList: "/products",
  productBids: (productId: string) => `/bids/${productId}`,
  placeBid: (productId: string) => `/bids/place/${productId}`,
  bidWinners: "/bids/winners",
  addProduct: "/admin/products/add",
  editProduct: (productId: string) => `/admin/products/edit/${productId}`,
};
