import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useSession } from "providers/SessionProvider"
import {
  Button,
  Form,
  InputGroup,
} from "react-bootstrap"
import Splash from "components/splash/Splash"
import LogoHeading from "components/headers/logo-heading/LogoHeading"
import { routes } from "constants/routes"
import { LoginDTO } from "interfaces/Login"
import { isNotNumericKey } from "utils/input.util"
import { LoginSchema } from "./login.schema"
import { setSessionData } from "services/session.service"
import "./Login.css"
import MessageToast from "components/toasts/MessageToast"

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { adminLogin, getUser, responseMessage, updateResponseMessage } = useSession();
  const formik = useFormik<LoginDTO>({
    initialValues: {
      mobileNumber: "",
      password: ""
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      return adminLogin(values)
        .then((res) => {
          setSessionData("token", res.data.token)
          getUser().then(res => {
            updateResponseMessage("Logged in successfully", true);
            navigate(routes.home);
          })
        })
        .catch((ex) => {
          updateResponseMessage(ex.message, false);
          console.log(ex)
        })
    },
  })

  if (formik.isSubmitting) {
    return <Splash />
  }

  return (
    <div className="login">
      <header>
        <LogoHeading />
      </header>
      <div className="my-5 pt-5">
        <h3>Login</h3>
      </div>
      <Form className="login-form" onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Label htmlFor="mobileNumber">Phone Number</Form.Label>
          <InputGroup className="has-validation">
            <Form.Control
              id="mobileNumber"
              name="mobileNumber"
              type="text"
              placeholder="Enter phone number"
              onKeyDown={(e) => isNotNumericKey(e.key) && e.preventDefault()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobileNumber}
              isInvalid={
                formik.touched.mobileNumber &&
                Boolean(formik.errors.mobileNumber)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.mobileNumber}
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Label htmlFor="password">Password</Form.Label>
          <InputGroup className="has-validation">
            <Form.Control
              id="password"
              name="password"
              type="password"
              placeholder="Enter password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              isInvalid={
                formik.touched.password &&
                Boolean(formik.errors.password)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <div>
          <Button type="submit" disabled={!formik.isValid}>
            Submit
          </Button>
        </div>
      </Form>
      <MessageToast message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
    </div>
  )
}

export default Login
