export function setSessionData(key: string, value: string) {
  return sessionStorage.setItem(key, value);
}

export function getSessionData(key: string) {
  return sessionStorage.getItem(key);
}

export function removeSessionData(key: string) {
  return sessionStorage.removeItem(key);
}

export function clearSessionData() {
  return sessionStorage.clear();
}
