export function omit<T extends {}, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key as K))
  ) as Omit<T, K>;
}

export function arrayToMap<T>(array: T[], key: keyof T): Map<unknown, T[]> {
  const arrMap = new Map<unknown, T[]>();
  array.reduce((map, obj) => {
    map.get(obj[key])?.push(obj) || map.set(obj[key], [obj]);
    return map;
  }, arrMap);
  return arrMap;
}
