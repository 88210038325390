import { format } from "date-fns";
import { ProductWinner } from "interfaces/Product";
import { getImageSrc } from "utils/string.util";
import { currencyFormatter } from "utils/number.util";
import defaultUser from "assets/icons/default-user.svg";
import "./WinnerListItem.css";

interface WinnerListItemProps {
    productWinner: ProductWinner;
}

const WinnerListItem: React.FC<WinnerListItemProps> = ({ productWinner }) => {
    return (
        <div className="product-item">
            <div>
                <img src={getImageSrc(productWinner.user.image, defaultUser)} alt={productWinner.user.name} />
            </div>
            <div className="product-item-button">
                <div>
                    <h5>{productWinner.user.name}</h5>
                    <p>{productWinner.productName}</p>
                    <p>
                        {`${format(new Date(productWinner.date), "PP p")}`}
                    </p>
                </div>
                <div>
                    <p>{currencyFormatter.format(productWinner.amount)}</p>
                </div>
            </div>
        </div>
    )
}

export default WinnerListItem;
