import { useProduct } from "providers/ProductProvider";
import { useNavigate } from "react-router-dom";
import { routes } from "constants/routes";
import React from "react";
import ProductListItem from "./ProductListItem";
import { Tab, Tabs } from "react-bootstrap";
import "./Products.css";

const Products: React.FC = () => {
    const navigate = useNavigate();
    const { auctionItems } = useProduct();

    function handleAddProducts() {
        navigate(routes.addProduct);
    }

    return (
        <div className="products">
            <Tabs
                defaultActiveKey="ongoing"
                className="mb-3"
                fill
            >
                <Tab eventKey="ongoing" title="Ongoing">
                    {auctionItems.ongoingAuctionItems.map(product => (
                        <React.Fragment key={product.id}>
                            <ProductListItem
                                product={product}
                                allowEdit={true}
                                allowDelete={false}
                            />
                        </React.Fragment>
                    ))}
                </Tab>
                <Tab eventKey="completed" title="Completed">
                    {auctionItems.completedAuctionItems.map(product => (
                        <React.Fragment key={product.id}>
                            <ProductListItem
                                product={product}
                                allowEdit={false}
                                allowDelete={true}
                            />
                        </React.Fragment>
                    ))}
                </Tab>
                <Tab eventKey="draft" title="Draft">
                    {auctionItems.draftAuctionItems.map(product => (
                        <React.Fragment key={product.id}>
                            <ProductListItem
                                product={product}
                                allowEdit={true}
                                allowDelete={true}
                            />
                        </React.Fragment>
                    ))}
                </Tab>
            </Tabs>
            <div>
                <div className="add-products">
                    <button type="button" onClick={handleAddProducts}>Add products</button>
                </div>
            </div>
        </div >
    )
}

export default Products;
