import { Image, Modal } from "react-bootstrap";
import "./ConfirmationModal.css";

interface ConfirmationModalProps {
    isShown: boolean;
    title: string;
    message: string;
    confirmButtonText: string;
    declineButtonText: string;
    onHide: (confirm: boolean) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isShown, title, message, confirmButtonText, declineButtonText, onHide, ...props }) => {
    return (
        <Modal
            {...props}
            show={isShown}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="text-center">
                <div>
                    <Image src="/assets/icons/success.svg" alt="" style={{ width: 22 }} />
                </div>
                <h4 style={{ marginBlock: "var(--spacing-1)" }}>
                    {title}
                </h4>
                <p>
                    {message}
                </p>
                <div>
                    <button type="button" className="modal-close-button" onClick={() => onHide(true)}>
                        {confirmButtonText}
                    </button>
                    <button type="button" className="modal-close-button" onClick={() => onHide(false)}>
                        {declineButtonText}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmationModal;
