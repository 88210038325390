import { Bid } from "interfaces/Bid";
import { useBid } from "providers/BidProvider";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, formatDuration, intervalToDuration, isBefore } from "date-fns";
import { Spinner, Table } from "react-bootstrap";
import { useProduct } from "providers/ProductProvider";
import { getImageSrc } from "utils/string.util";
import { routes } from "constants/routes";
import { currencyFormatter } from "utils/number.util";
import "./BidList.css";

const BidList: React.FC = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const { getBidsByProductId } = useBid();
    const { updateSelectedProduct, selectedProduct, biddingEndDate } = useProduct();
    const [bidsList, setBidsList] = useState<Bid[]>([]);
    const [countdown, setCountdown] = useState<string>("");
    const [countdownEnded, setCountdownEnded] = useState<boolean>(false);

    function handlePlaceBids(productId: string) {
        navigate(routes.placeBid(productId));
    }

    useEffect(() => {
        if (productId) {
            updateSelectedProduct(productId);
            getBidsByProductId(productId)
                .then(res => {
                    if (res) {
                        setBidsList(res);
                    }
                })
                .catch(ex => {
                    console.log(ex);
                });
        }

        return () => updateSelectedProduct();
    }, [productId, getBidsByProductId, updateSelectedProduct]);

    useEffect(() => {
        if (biddingEndDate) {
            const interval = setInterval(() => {
                const now = new Date();
                const duration = intervalToDuration({ start: now, end: biddingEndDate });

                if (isBefore(biddingEndDate, now)) {
                    setCountdownEnded(true);
                    clearInterval(interval);
                }

                setCountdown(`${formatDuration(duration)}`);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [biddingEndDate]);

    if (!countdownEnded && !countdown) {
        return (<div className="centered py-5">
            <Spinner animation="border" variant="primary" />
        </div>)
    }

    return (
        <div className="bids">
            {selectedProduct && (<div className="product-overview">
                <div className="image-container">
                    <img src={getImageSrc(selectedProduct.image1)} alt={selectedProduct.name} />
                </div>
                <div>
                    <h3>{selectedProduct.name}</h3>
                    <p>{selectedProduct.description}</p>
                    <p>{countdownEnded ? "Bidding closed" : "Bidding ends in:"}</p>
                    {!countdownEnded && <p>{`${countdown}`}</p>}
                </div>
            </div>)}
            {!countdownEnded && selectedProduct && (
                <button type="button" className="w-100" onClick={() => handlePlaceBids(selectedProduct.id)}>Place bid</button>
            )}
            {bidsList.length
                ? (<Table responsive>
                    <thead>
                        <tr>
                            <th>User name</th>
                            <th>Bid amount</th>
                            <th>Bid placed at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bidsList.map((bid, index) => (<tr key={bid.id} className="bid-item">
                            <td>{index === 0 ? (<i className="bi bi-trophy d-inline-block me-1"></i>) : ""}{bid.user.name}</td>
                            <td>{currencyFormatter.format(bid.bidAmount)}</td>
                            <td>{format(new Date(bid.bidTime), "dd MMM yyyy HH:mm:ss")}</td>
                        </tr>))}
                    </tbody>
                </Table>)
                : (
                    <div className="no-data">
                        <p>No bids were placed!</p>
                    </div>
                )}
        </div>
    )
}

export default BidList;
