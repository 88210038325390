import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useProduct } from "providers/ProductProvider";
import { useNavigate, useParams } from "react-router-dom";
import { ProductFormDTO } from "interfaces/Product";
import closeIcon from "assets/icons/close.svg";
import { Form, InputGroup } from "react-bootstrap";
import { routes } from "constants/routes";
import DatePicker from "react-datepicker";
import { endOfDay, format } from "date-fns";
import { AddProductSchema } from "./add-product.schema";
import MessageToast from "components/toasts/MessageToast";
import { useSession } from "providers/SessionProvider";
import { formatInTimeZone } from "date-fns-tz";
import { DATE_FORMAT_STANDARD } from "constants/values";
import "./AddProduct.css";

interface ImagePreviews {
    image1: string | null,
    image2: string | null,
    image3: string | null
}

function getKeyOrder(previews: ImagePreviews) {
    return Object.entries(previews).sort((a, b) => {
        if (!a[1]) {
            return -1;
        }

        if (!b[1]) {
            return 1;
        }

        return Number.parseInt(a[0].at(-1) || "0") - Number.parseInt(b[0].at(-1) || "0");
    }).map(([k, v]) => k);
}

const AddProduct: React.FC = () => {
    const { productId } = useParams();
    const { responseMessage, updateResponseMessage } = useSession();
    const { updateSelectedProduct, selectedProduct } = useProduct();
    const imageInputRef = useRef<HTMLInputElement | null>(null);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [imagePreviews, setImagePreviews] = useState<ImagePreviews>({
        image1: null,
        image2: null,
        image3: null
    });
    const navigate = useNavigate();
    const { addProduct, editProduct, getProducts } = useProduct();
    const { setValues, setFieldValue, ...formik } = useFormik<ProductFormDTO>({
        initialValues: {
            name: "",
            description: "",
            minBidAmount: "",
            bidStartTime: "",
            bidEndTime: "",
            image1: null,
            image2: null,
            image3: null,
            isDraft: false
        },
        validationSchema: AddProductSchema,
        onSubmit: async (values) => {
            try {
                if (productId) {
                    return editProduct(productId, {
                        ...values,
                        minBidAmount: Number.parseFloat(values.minBidAmount),
                        bidStartTime: formatInTimeZone(new Date(values.bidStartTime), "UTC", DATE_FORMAT_STANDARD),
                        bidEndTime: formatInTimeZone(new Date(values.bidEndTime), "UTC", DATE_FORMAT_STANDARD)
                    })
                        .then(res => {
                            updateResponseMessage("Auction item edited", true);
                            if (res) {
                                getProducts();
                                navigate(routes.home);
                            }
                        })
                        .catch((ex) => {
                            updateResponseMessage(ex.message, false);
                        });
                } else {
                    return addProduct({
                        ...values,
                        minBidAmount: Number.parseFloat(values.minBidAmount),
                        bidStartTime: formatInTimeZone(new Date(values.bidStartTime), "UTC", DATE_FORMAT_STANDARD),
                        bidEndTime: formatInTimeZone(new Date(values.bidEndTime), "UTC", DATE_FORMAT_STANDARD)
                    })
                        .then(res => {
                            updateResponseMessage("Auction item added", true);
                            if (res) {
                                getProducts();
                                navigate(routes.home);
                            }
                        })
                        .catch((ex) => {
                            updateResponseMessage(ex.message, false);
                        });

                }
            } catch (ex) {
                console.log(ex)
                return Promise.reject(ex);
            }
        }
    });

    function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
        const max_files = 3;

        if (!e.currentTarget.files) {
            return;
        }

        const keyOrder = getKeyOrder(imagePreviews);

        for (let i = 0; i < max_files; ++i) {
            const file = e.currentTarget.files.item(i);

            if (file) {
                setFieldValue(keyOrder[i], file);

                const reader = new FileReader();

                reader.onload = (e) => {
                    if (e.target?.result) {
                        const imageUrl = e.target.result as string;
                        setImagePreviews(prevState => {
                            return { ...prevState, [keyOrder[i]]: imageUrl }
                        });
                    }
                };

                reader.readAsDataURL(file);
            } else {
                break;
            }
        }
    }

    function handleImageClose(imageId: string) {
        if (imageInputRef.current) {
            imageInputRef.current.value = null as unknown as string;
        }
        setFieldValue(imageId, "");
        setImagePreviews(prevState => ({ ...prevState, [imageId]: null }));
    }

    function handleDateChange(date: Date, type: "START" | "END") {
        if (type === "START") {
            setStartDate(date);
            setFieldValue("bidStartTime", format(date, "yyyy-MM-dd HH:mm:ss"));
        } else if (type === "END") {
            setEndDate(date);
            setFieldValue("bidEndTime", format(date, "yyyy-MM-dd HH:mm:ss"));
        }
    }

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }

        if (startDate.getTime() > endDate.getTime()) {
            setEndDate(endOfDay(startDate));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (productId) {
            updateSelectedProduct(productId);
        }

        return () => updateSelectedProduct();
    }, [productId, updateSelectedProduct]);

    useEffect(() => {
        if (selectedProduct) {
            setValues({
                name: selectedProduct.name,
                description: selectedProduct.description,
                minBidAmount: String(selectedProduct.minBidAmount),
                bidStartTime: selectedProduct.bidStartTime,
                bidEndTime: selectedProduct.bidEndTime,
                image1: null,
                image2: null,
                image3: null,
                isDraft: false
            });

            setImagePreviews(prevState => ({
                ...prevState,
                image1: selectedProduct.image1,
                image2: selectedProduct.image2,
                image3: selectedProduct.image3,
            }));

            setStartDate(new Date(selectedProduct.bidStartTime));
            setEndDate(new Date(selectedProduct.bidEndTime));
        }
    }, [selectedProduct, setValues, setFieldValue]);

    return (
        <div className="add-product">
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                    <Form.Label htmlFor="name" className="required">Name</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            isInvalid={formik.touched.name && Boolean(formik.errors.name)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="description">Description</Form.Label>
                    <Form.Control
                        id="description"
                        name="description"
                        as="textarea"
                        rows={3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="minimum-bid-amount" className="required">Minimum bid amount</Form.Label>
                    <InputGroup hasValidation>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                            id="minimum-bid-amount"
                            name="minBidAmount"
                            type="text"
                            inputMode="numeric"
                            placeholder="Enter Bid Amount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.minBidAmount}
                            isInvalid={formik.touched.minBidAmount && Boolean(formik.errors.minBidAmount)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.minBidAmount}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Bidding duration</Form.Label>
                    <div className="datepicker-wrapper">
                        <div className="label">
                            <p className="required">Start date</p>
                        </div>
                        <DatePicker
                            selected={startDate}
                            startDate={startDate || undefined}
                            endDate={endDate || undefined}
                            minDate={new Date()}
                            onChange={(date) => date && handleDateChange(date, "START")}
                            customInput={
                                <Form.Control
                                    isInvalid={formik.touched.bidStartTime && Boolean(formik.errors.bidStartTime)}
                                />
                            }
                            placeholderText="Enter start date"
                            dateFormat="dd MMM yyyy, HH:mm"
                            selectsStart
                            showTimeSelect
                            showYearDropdown
                            withPortal
                        />
                    </div>
                    <Form.Control.Feedback type="invalid" className={formik.touched.bidStartTime && Boolean(formik.errors.bidStartTime) ? "d-block" : ""}>
                        {formik.errors.bidStartTime}
                    </Form.Control.Feedback>
                    <div className="datepicker-wrapper">
                        <div className="label">
                            <p className="required">End date</p>
                        </div>
                        <DatePicker
                            selected={endDate}
                            startDate={startDate || undefined}
                            endDate={endDate || undefined}
                            minDate={startDate || new Date()}
                            onChange={(date) => date && handleDateChange(date, "END")}
                            customInput={<Form.Control
                                isInvalid={formik.touched.bidEndTime && Boolean(formik.errors.bidEndTime)}
                            />}
                            placeholderText="Enter end date"
                            dateFormat="dd MMM yyyy, HH:mm"
                            selectsEnd
                            showTimeSelect
                            showYearDropdown
                            withPortal
                        />
                    </div>
                    <Form.Control.Feedback type="invalid" className={formik.touched.bidEndTime && Boolean(formik.errors.bidEndTime) ? "d-block" : ""}>
                        {formik.errors.bidEndTime}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Product images</Form.Label>
                    <Form.Control
                        name="images"
                        type="file"
                        ref={imageInputRef}
                        onChange={handleFileSelect}
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple
                    />
                    <button type="button" onClick={() => imageInputRef?.current?.click()}>Upload images</button>
                </Form.Group>
                <div className="image-preview">
                    {Object.entries(imagePreviews).map(([key, value]) =>
                    (Boolean(value) ? (<div key={key}>
                        <img src={value} alt="preview" />
                        <button type="button" onClick={() => handleImageClose(key)}>
                            <img src={closeIcon} alt="close" />
                        </button>
                    </div>) : null)
                    )}
                </div>
                {!Boolean(selectedProduct?.isDraft) && (<Form.Group>
                    <Form.Check
                        type="checkbox"
                        id="isDraft"
                        name="isDraft"
                        label="Save as Draft"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={`${formik.values.isDraft}`}
                    />
                </Form.Group>)}
                <button type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                    {`${selectedProduct?.isDraft ? "Publish" : productId ? "Edit product" : "Add product"}`}
                </button>
            </Form>
            <MessageToast message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
        </div>
    )
}

export default AddProduct;
