import { Image } from "react-bootstrap";
import logoImage from "assets/icons/logo.svg";
import "./LogoHeading.css";

const LogoHeading: React.FC = () => {
    return (
        <div className="logo-heading">
            <Image src={logoImage} />
            <h3>Auction Masters</h3>
        </div>
    )
}

export default LogoHeading;
