import { router } from 'AppRoutes';
import './App.scss';
import { RouterProvider } from 'react-router-dom';
import { ProductProvider } from 'providers/ProductProvider';
import { BidProvider } from 'providers/BidProvider';

function App() {
  return (
    <div className="app">
      <ProductProvider>
        <BidProvider>
          <RouterProvider router={router} />
        </BidProvider>
      </ProductProvider>
    </div>
  );
}

export default App;
